<template>
	<router-view></router-view>
</template>

<script>

export default {
	name: 'App',
	components: {
	}
}
</script>

<style>
:root {
	--primary-color: #FF8900;
	--background-color: #1f1f35;
	--white-color: #E6D5B8;
	--red-color: #e45826;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}
</style>
