<template>
	<div class="payment-page-container">
		<div v-if="this.showErrorMsg === true" class="payment-error-display-container">
			{{ this.errorMsg }}
		</div>
		<div v-if="this.showErrorMsg === false" class="payment-display-container">
			<div class="payment-display-title">
				套餐：{{ this.paymentTitle }}</div>
			<div class="payment-display-price">
				价格：￥{{ this.amount }}</div>
			<div class="payment-display-duration">
				有效期：{{ this.planDuration }}天</div>
			<div class="payment-display-duration">
				支付方式：{{ this.paymentMethod }}</div>
			<div v-if="this.paymentType === 'wechatpay'">
				<div class="payment-wechatpay-method">方式一：扫描下面二维码进行支付</div>
				<div class="qr-canvas-wrapper">
					<canvas id="wx_qr_canvas" style="margin-top: 1em; width: 5em;height: 5em;"/>
				</div>
				<div class="payment-wechatpay-method-2">方式二：
					<div>1.复制支付链接；</div>
					<div>2.打开微信；</div>
					<div>3.将支付链接发送给自己；</div>
					<div>4.点击链接进行支付。</div>
				</div>
				<div class="payment-wechat-btn" @click="copyPaymentLink">复制支付链接</div>
				<div class="payment-wechat-btn" @click="openWechat">打开微信</div>
			</div>

			<div v-if="this.paymentType === 'alipay'">
				<div class="payment-wechatpay-method">方式一：扫描下面二维码进行支付</div>
				<div class="qr-canvas-wrapper">
					<canvas id="ali_qr_canvas" style="margin-top: 1em; width: 5em;height: 5em;"/>
				</div>
				<div class="payment-wechatpay-method-2">方式二：
					<div>1.截图本页面；</div>
					<div>2.打开支付宝；</div>
					<div>3.选择扫码支付；</div>
					<div>4.点击相册，选择本页面截图进行扫码。</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import {postRequestAsync, showToast, showLoading, hideLoading} from "@/utils/methods";
import QRCode from 'qrcode'
import copy from "copy-to-clipboard";

export default {
	name: "PaymentPage",
	data() {
		return {
			paymentId: null,
			showErrorMsg: false,
			errorMsg: '',
			paymentTitle: '',
			paymentLink: '',
			paymentLinkDisplayType: '',
			amount: '',
			planDuration: '',
			paymentMethod: '',
			paymentType: '',
		}
	},
	async mounted() {
		this.paymentId = this.$route.query.id;
		if (!this.paymentId) {
			this.showErrorMsg = true
			this.errorMsg = '参数错误，请重试。'
			return
		}
		await this.getPaymentInfo()
	},
	methods: {
		async getPaymentInfo() {
			showLoading()
			const response = await postRequestAsync('webQuery/getPaymentInfo', {id: this.paymentId})
			hideLoading()
			if (response.code === 200) {
				this.paymentTitle = response.msg.planName
				this.paymentLinkDisplayType = response.msg.displayType
				this.planDuration = response.msg.planDuration
				this.amount = parseFloat(response.msg.amount / 100).toFixed(2)
				this.paymentMethod = response.msg.productId.indexOf('wechatpay') >=0 ? '微信支付' : '支付宝'
				this.paymentType = response.msg.productId.indexOf('wechatpay') >=0 ? 'wechatpay' : 'alipay'
				this.paymentLink = response.msg.paymentLink
				if (this.paymentLinkDisplayType === 'qrcode') {
					this.generateQrCode(response.msg.paymentLink)
				}

				if (this.paymentType === 'wechatpay' && this.paymentLinkDisplayType === 'payurl') {
					window.open(this.paymentLink, '_self');
				}
				if(this.paymentType === 'alipay' && this.paymentLinkDisplayType === 'payurl') {
					window.open(this.paymentLink, '_self');
				}

			} else {

				this.showErrorMsg = true
				this.errorMsg = '参数错误，请重试。'
			}
		},
		generateQrCode (link, time = 1, maxTime = 5) {
			if (time >= maxTime) {
				return
			}
			const canvasId = this.paymentType === 'wechatpay' ? 'wx_qr_canvas' : 'ali_qr_canvas'
			const canvas = document.getElementById(canvasId)
			if (!canvas) {
				setTimeout(() => {
					this.generateQrCode(link, ++time, maxTime)
				}, 1000)
				return
			}
			QRCode.toCanvas(canvas, link, function (error) {
				if (error) {
					console.error(error)
					return
				}
				console.log('success!');
			})
		},
		copyPaymentLink() {
			copy(this.paymentLink)
			showToast('复制成功')
		},
		openWechat() {
			window.location.href = 'weixin://';
		}
	}
}
</script>

<style scoped>
.payment-page-container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-color: var(--background-color);
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
}

.payment-error-display-container {
	color: var(--red-color);
	font-size: 1.6em;
	margin-top: 2em;
}

.payment-display-container {
	margin-bottom: 10em;
}

.payment-display-title{
	color: var(--primary-color);
	font-size: 1.4em;
	margin-top: 2em;
}

.payment-display-price{
	color: var(--white-color);
	font-size: 1.4em;
	margin-top: 0.4em;
}
.payment-display-duration{
	color: var(--white-color);
	font-size: 1.4em;
	margin-top: 0.4em;
}

.payment-wechatpay-method{
	color: var(--white-color);
	font-size: 1.2em;
	margin-top: 0.8em;
}
.payment-wechatpay-method-2{
	display: flex;
	flex-direction: column;
	width: fit-content;
	align-items: start;
	color: var(--white-color);
	font-size: 1.2em;
	margin-top: 0.8em;
}

.payment-wechat-btn{
	font-size: 1.2em;
	padding: 0.4em 1em;
	background-color: var(--white-color);
	border-radius: 0.2em;
	width: fit-content;
	margin: auto;
	margin-top: 0.8em;
}
</style>