import {createRouter, createWebHistory} from 'vue-router';
import PaymentPage from './components/PaymentPage.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{path: '/', component: PaymentPage, name: 'paymentPage'}
	]
});

export default router;